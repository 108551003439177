const carouselData = [
  {
    id: 0,
    title: "AH Consulting",
    desc: "Public Health Startup site",
    figma: "https://www.figma.com/file/pfcJeNmd8TFN7Cfu33OGgp/AH-Consulting",
  },
  {
    id: 1,
    title: "Optimised Reach",
    desc: "Simple landing page for Social Media company",
    site: "https://optimised-reach.netlify.app/",
    github: "https://github.com/Samatarx/OR-AW-Landing-Page",
  },
  {
    id: 2,
    title: "React Menu",
    desc: "Menu site created using React",
    site: "https://samatar-react-menu.netlify.app/",
    github: "https://github.com/Samatarx/React-Food-Menu",
  },
  {
    id: 3,
    title: "React FAQs",
    desc: "Dropdown FAQ components made with React",
    site: "https://samatar-react-faqs.netlify.app/",
    github: "https://github.com/Samatarx/React-FAQs",
  },
  {
    id: 5,
    title: "Grocery List",
    desc: "To-do style Grocery list using local storage",
    site: "https://samatar-react-grocery-list.netlify.app/",
    github: "https://github.com/Samatarx/Grocery-List",
  },
  {
    id: 6,
    title: "React Colour Generator",
    desc: "Colour generator made with React",
    site: "https://samatar-react-colours.netlify.app/",
    github: "https://github.com/Samatarx/React-Colour-Generator",
  },
  {
    id: 7,
    title: "Native React Carousel",
    desc: "Review carousel",
    site: "https://samatar-reviews.netlify.app/",
    github: "https://github.com/Samatarx/Reviews",
  },
  {
    id: 8,
    title: "First Solo React Project",
    desc: "Reusable Components made with React",
    site: "https://tmnt-react.netlify.app/",
    github: "https://github.com/Samatarx/TMNT",
  },
  {
    id: 9,
    title: "Background Slider",
    desc: "Simple slider created using Vanilla JS",
    site: "https://50projects50days-18th.netlify.app/",
    github: "https://github.com/Samatarx/Background-Slider",
  },
  {
    id: 10,
    title: "Drink Water App",
    desc: "Interactive drink water site",
    site: "https://50projects50days-16th.netlify.app/",
    github: "https://github.com/Samatarx/Drink-Water",
  },
  {
    id: 11,
    title: "Animated Navbar",
    desc: "An animated navigation bar",
    site: "https://50projects50days-14th.netlify.app/",
    github: "https://github.com/Samatarx/Animated-Navigation",
  },
  {
    id: 12,
    title: "Events API site",
    desc: "Random event generator using a public API",
    site: "https://50projects50days-10th.netlify.app/",
    github: "https://github.com/Samatarx/Acitivity-Generator-",
  },
  {
    id: 13,
    title: "Blurred Landing Page",
    desc: "Landing page created using Javascript and CSS",
    site: "https://50projects50days-10th.netlify.app/",
    github: "https://github.com/Samatarx/Acitivity-Generator-",
  },
  {
    id: 14,
    title: "Typewriter",
    desc: "Vanilla JS typewriter with error detection",
    site: "http://samatars-typing-speed-tester.netlify.app/",
    github: "https://github.com/Samatarx/Typing-Speed-Tester",
  },
  {
    id: 15,
    title: "Random Colours and Gradients",
    desc: "Generator created using JS",
    site: "https://randomcoloursandgradients.netlify.app/",
    github: "https://github.com/Samatarx/Random-Colours-and-Gradients",
  },
  {
    id: 16,
    title: "Technical Documentation",
    desc: "FreeCodeCamp technical docs project",
    site: "https://codepen.io/samatarx/full/PozveBr",
  },
  {
    id: 17,
    title: "Menu Form",
    desc: "FreeCodeCamp form project",
    site: "https://codepen.io/samatarx/full/BajXLej",
  },
  {
    id: 18,
    title: "Product Landing Page",
    desc: "Created using only HTML and CSS",
    site: "https://codepen.io/samatarx/full/bGpGmdb",
  },
  {
    id: 19,
    title: "First Portfolio",
    desc: "Final Project of FCC first Certificate",
    site: "https://codepen.io/samatarx/full/mdEZrOj",
  },
  {
    id: 20,
    title: "First Website",
    desc: "First full site made using HTML and CSS",
    site: "https://codepen.io/samatarx/full/eYJapPW",
  },
  {
    id: 21,
    title: "SRIG",
    desc: "A site that links Netlify forms to Mailchimp using Zapier",
    site: "https://srig.so",
  },
  {
    id: 22,
    title: "Sweet One",
    desc: "Online booking landing page for a small business",
    site: "https://sweetoneldn.co.uk/",
    figma:
      "https://www.figma.com/file/b6EWwMReFt6ZDPIQbs3Xtk/Sweet-One-Landing-Page-Concept?node-id=0%3A1",
  },
];

export default carouselData;
